import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormsErrorHandlerService } from '../forms-error-handler.service';
import { FieldDescriptor, UserInfoService } from './user-info.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, ReplaySubject } from 'rxjs';
import { ToastMessageService } from '../../shared/modules/toast-message/toast-message.service';
import { UserService } from './user.service';
import { UserFieldDescriptor, userFields, UserFieldType } from '../../helpers/user/user-fields.data';
import { CustomValidators } from '../../helpers/custom-validators';
import { ValidationPatterns } from '../../helpers/validation-patterns';
import { ZendeskChatService } from '../zendesk/zendesk-chat.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /**
   * Is form loading
   */
  public loading: boolean;

  public userProfileUpdated$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public errors = {};

  public isFullDataFilled: boolean;

  constructor(
    private _formErrors: FormsErrorHandlerService,
    private _userInfo: UserInfoService,
    private _toastMessage: ToastMessageService,
    private _user: UserService,
    private _zendesk: ZendeskChatService,
  ) {}

  /**
   * Submit form handler
   */
  submit(form: FormGroup, context: string) {
    this._formErrors.applyFormErrors(form, null, true);

    if (form.invalid) {
      return;
    }

    const formValue = {
      ...form.value,
      postal_code: this._userInfo.isCA ? form.value?.postal_code?.toUpperCase() : form.value?.postal_code,
    };

    this.loading = true;

    this._userInfo
      .updatePlayerInfo(formValue, context)
      .pipe(
        tap(() => {
          this._toastMessage.success('t.profile-updated');
          Object.keys(form.controls).forEach(key => {
            form.controls[key].disable();
          });
          this.isFullDataFilled = this.checkFullData(form);
        }),
        switchMap(() => this._user.fetchAllUserData()),
      )
      .subscribe(
        () => {
          this.loading = false;
          this.userProfileUpdated$.next(true);
          setTimeout(() => {
            this._zendesk.setUserData();
          }, 5000);
        },
        error => {
          this.loading = false;
          this.errors = this._formErrors.applyFormErrors(form, error.error, true);
          this.userProfileUpdated$.next(false);
        },
      );
  }

  public resolveFields(context: string = 'edition'): Observable<(UserFieldDescriptor & FieldDescriptor)[]> {
    return this._userInfo.missingAttributesFor(context).pipe(
      map(missedFields =>
        missedFields.map((field: FieldDescriptor) => {
          const options = Boolean(field?.inclusion?.in?.length && field?.field !== 'gender')
            ? {
                options: of(field.inclusion.in).pipe(map(data => data.map(op => [op, op] as [string, string]))),
              }
            : {};
          return userFields.has(field.field)
            ? {
                ...field,
                ...userFields.get(field.field),
                ...options,
                validators:
                  this._userInfo.isCA && field.field === 'postal_code'
                    ? [
                        Validators.required,
                        CustomValidators.changeKey('ca_postal_code_error', Validators.pattern(ValidationPatterns.canadianPostalCode)),
                      ]
                    : userFields.get(field.field)?.validators,
              }
            : {
                ...field,
                type: field.type === 'boolean' ? UserFieldType.CHECKBOX : UserFieldType.TEXT,
                validators:
                  this._userInfo.isCA && field.field === 'postal_code'
                    ? [
                        Validators.required,
                        CustomValidators.changeKey('ca_postal_code_error', Validators.pattern(ValidationPatterns.canadianPostalCode)),
                      ]
                    : [Validators.required],
                label: field.field,
                ...options,
              };
        }),
      ),
    );
  }

  public checkFullData(group: FormGroup): boolean {
    return Object.keys(group.controls).every(key => group.controls[key].value);
  }
}
