import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { ApiProvider } from './helpers/api-provider';
import { map } from 'rxjs/operators';
import { ApiProviderFactoryService } from './helpers/api-provider-factory.service';

@Injectable({
  providedIn: 'root',
})
export class MhlApiService {
  private _api: ApiProvider = this._apiFactory.create({
    defaultOptions: {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile',
      }),
      withCredentials: true,
    },
    host: environment.mlh_host,
    urlResolutionFn: () =>
      this._lang.langChange$.pipe(
        map(this._lang.normalizeLangForApi),
        map(lang => '/' + lang),
      ),
  });

  constructor(
    private _device: DeviceDetectorService,
    private _lang: LanguageService,
    private _apiFactory: ApiProviderFactoryService,
  ) {}

  public get customHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile',
    });
  }

  public get customHeadersList(): any[] {
    return this._api.customHeadersList;
  }

  /**
   * POST /personal-offer/finish
   *
   * @param params
   */
  public postDeclineOffer(data: object = {}, params: object = {}): Observable<any> {
    return this._api.post(`/personal-offer/finish`, data, { params });
  }
}
