import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';

@UntilDestroy()
@Directive({
  selector: '[appDobMask]',
  standalone: true,
})
export class DobMaskDirective implements OnInit, OnDestroy {
  private _lastValue: string;

  constructor(private _control: NgControl) {}

  ngOnInit(): void {
    this._control.valueChanges
      .pipe(
        untilDestroyed(this),
        map(value => this._filterCharacters(value)),
        map(value => this._compareValues(value)),
        map(value => this._mask(value)),
        tap(value => {
          this._lastValue = value;
          this._control.control.setValue(value, {
            emitEvent: false,
          });
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  /**
   * Remove all invalid characters
   *
   * @param value
   * @private
   */
  private _filterCharacters(value: string): string {
    return value
      ? value
          .split('')
          .filter(char => /[0-9]/.test(char) || char === '/')
          .join('')
      : '';
  }

  /**
   * Compare previous and current value for correct deleting
   *
   * @param current
   * @private
   */
  private _compareValues(current: string): string {
    if (
      this._lastValue &&
      current.length < this._lastValue.length &&
      current[current.length - 1] !== this._lastValue[this._lastValue.length - 1] &&
      this._lastValue[this._lastValue.length - 1] === '/'
    ) {
      return current
        .split('')
        .splice(0, current.length - 1)
        .join('');
    }

    return current;
  }

  /**
   * Mask value
   *
   * @param value
   * @private
   */
  private _mask(value: string): string {
    const slashCount = value.split('').filter(char => char === '/').length;

    if (value.length === 2 && slashCount < 2) {
      value = value + '/';
    }
    if (value.length === 5 && slashCount < 2) {
      value = value + '/';
    }
    if (value.length > 10) {
      value = value.split('').splice(0, 10).join('');
    }

    return value;
  }
}
