export const LoyaltyGroups = [
  { name: 'Beginner Panda', BOidentifier: 'beginner_panda', id: 'ID1' },
  { name: 'Mini Panda', BOidentifier: 'mini_panda', id: 'ID2' },
  { name: 'Explorer Panda', BOidentifier: 'explorer_panda', id: 'ID3' },
  { name: 'Braveheart Panda', BOidentifier: 'brave_panda', id: 'ID4' },
  { name: 'Shadow Panda', BOidentifier: 'shadow_panda', id: 'ID5' },
  { name: 'Warrior Panda', BOidentifier: 'warrior_panda', id: 'ID6' },
  { name: 'Mischief Panda', BOidentifier: 'mischief_panda', id: 'ID7' },
  { name: 'Thunder Panda', BOidentifier: 'thunder_panda', id: 'ID8' },
  { name: 'Wise Panda', BOidentifier: 'wise_panda', id: 'ID9' },
  { name: 'Mystic Panda', BOidentifier: 'mystic_panda', id: 'ID10' },
  { name: 'Golden Panda', BOidentifier: 'golden_panda', id: 'ID11' },
  { name: 'Legendary Panda', BOidentifier: 'legendary_panda', id: 'ID12' },
  { name: 'Majestic Panda', BOidentifier: 'majestic_panda', id: 'ID13' },
  { name: 'Master Panda', BOidentifier: 'master_panda', id: 'ID14' },
];
