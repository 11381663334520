import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserFieldDescriptor, UserFieldType } from '../../../helpers/user/user-fields.data';
import { FieldDescriptor, UserInfoService } from '../../../services/user/user-info.service';
import { UserProfileService } from '../../../services/user/user-profile.service';
import { UserService } from '../../../services/user/user.service';
import { EnvironmentService } from '../../../services/environment.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BtnComponent } from '../btn/btn.component';
import { PhoneFormComponent } from '../phone-form/phone-form.component';
import { SelectOptionComponent } from '../../modules/select/select-option/select-option.component';
import { SelectDropdownComponent } from '../../modules/select/select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from '../../modules/select/select-header/select-header.component';
import { SelectComponent } from '../../modules/select/select.component';
import { DobMaskDirective } from '../../directives/dob-mask.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { FormInputComponent } from '../form-input/form-input.component';
import { TranslatePipe } from '../../translation/translate.pipe';
import { ArrayService } from 'ngx-unificator/services';

@UntilDestroy()
@Component({
    selector: 'app-auth-fields-missed',
    templateUrl: './auth-fields-missed.component.html',
    styleUrls: ['./auth-fields-missed.component.scss'],
    standalone: true,
    imports: [
    ReactiveFormsModule,
    FormInputComponent,
    NgTemplateOutlet,
    NgxMaskDirective,
    NgClass,
    DobMaskDirective,
    SelectComponent,
    SelectHeaderComponent,
    SelectDropdownComponent,
    SelectOptionComponent,
    PhoneFormComponent,
    BtnComponent,
    AsyncPipe,
    TranslatePipe
],
})
export class AuthFieldsMissedComponent implements OnInit {

  @Input() fieldsList$: Observable<(UserFieldDescriptor & FieldDescriptor)[]>;
  @Input() hideTitle: boolean = false;
  @Input() buttonName: string = 't.save';

  @Output() onSubmit$: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * Missed fields form
   */
  public form: FormGroup = this._fb.group({});

  public readonly FieldType = UserFieldType;

  public fieldList: (UserFieldDescriptor & FieldDescriptor)[] = [];

  constructor(
    private _fb: FormBuilder,
    private _array: ArrayService,
    public userProfile: UserProfileService,
    public user: UserService,
    public userInfo: UserInfoService,
    public env: EnvironmentService,
  ) {
  }

  ngOnInit() {
    this._initForm();
  }

  public onSubmit() {
    this.onSubmit$.next(this.form);
  }

  /**
   * Init required form fields
   * @private
   */
  private _initForm() {
    this.fieldsList$.pipe(
      untilDestroyed(this),
    ).subscribe(e => {
      this.fieldList = e;
      this.fieldList = this._array.mapOrder(this.fieldList.filter(field => field.field !== 'nickname'),
        [
          'first_name',
          'last_name',
          'gender',
          'date_of_birth',
          'country',
          'state',
          'city',
          'address',
          'postal_code',
          'mobile_phone'
        ], 'field');
      this.form = this._buildForm(this.fieldList);
      this.userInfo.initAutocomplete(this.form);
    });
  }

  private _buildForm(fields: UserFieldDescriptor[] & FieldDescriptor[] | any): FormGroup {
    const form: FormGroup = new FormGroup({});
    fields.forEach((field: UserFieldDescriptor & FieldDescriptor) => {
      form.addControl(field.field, this._fb.control(null, field.validators));
    });
    return form;
  }
}
