import {
  BonusCancelConfirmationComponent
} from '../lazy/bonus-cancel-confirmation/bonus-cancel-confirmation.component';
import {
  AuthFieldsMissedUpdateModalComponent
} from '../lazy/app-auth-fields-missed-update/app-auth-fields-missed-update-modal.component';

export const ModalRegularListImports = [];

export const ModalRegular = {
  // SIGN_UP: SignUpModalComponent,
  // SIGN_IN: SignInModalComponent,
  // TERMS_ACCEPT: TermsAcceptanceFormComponent,
  AUTH_FIELD_MISSED_UPDATE: AuthFieldsMissedUpdateModalComponent,
  // PAYMENT: PaymentComponent,
  // SIGN_UP_LEAVE: SignUpLeaveModalComponent,
  // PNP: PayNPlayComponent,
  // AUTH_FIELD_MISSED: AuthFieldsMissedFormComponent,
  // GAME_NOT_AVAILABLE: GameNotAvailableComponent,
  // GAME_REQUIRES_FLASH: GameRequiresFlashComponent,
  BONUS_CANCEL_CONFIRM: BonusCancelConfirmationComponent,
  // BONUS_GUIDE: ClaimBonusesModalComponent,
  // EASTER_HUNT: EasterHuntModalComponent,
  // BONUS_TERMS: BonusTermsModalComponent,
};

export type ModalRegularType = keyof typeof ModalRegular;
