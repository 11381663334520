import { Observable } from 'rxjs';
import { ValidatorFn, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CountryList$, CurrencyList$, GenderList$ } from '../../services/common-data.service';
import { ValidationPatterns } from '../validation-patterns';
import { CustomValidators } from '../custom-validators';

export enum UserFieldType {
  TEXT = 'text',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  PHONE = 'phone',
  RADIO = 'radio',
}

export interface UserFieldDescriptor {
  type: UserFieldType;
  label: string;
  validators?: ValidatorFn[];
  mask?: string;
  options?: Observable<[string, string][]>;
}

export const userFields: Map<string, UserFieldDescriptor> = new Map<string, UserFieldDescriptor>([
  [
    'gender',
    {
      label: 't.gender',
      type: UserFieldType.RADIO,
      validators: [Validators.required],
      options: GenderList$,
    },
  ],
  [
    'date_of_birth',
    {
      label: 't.date-of-birth',
      type: UserFieldType.DATE,
      validators: [Validators.required, Validators.pattern(ValidationPatterns.dateOfBirth), CustomValidators.eighteenYearsOld],
    },
  ],
  [
    'first_name',
    {
      label: 't.first-name',
      type: UserFieldType.TEXT,
      validators: [Validators.required, Validators.pattern(ValidationPatterns.lettersWithSpaces)],
    },
  ],
  [
    'last_name',
    {
      label: 't.last-name',
      type: UserFieldType.TEXT,
      validators: [Validators.required, Validators.pattern(ValidationPatterns.lettersWithSpaces)],
    },
  ],
  [
    'mobile_phone',
    {
      label: 't.phone-number',
      type: UserFieldType.PHONE,
      validators: [Validators.required],
    },
  ],
  [
    'mobile_phone_without_dial_code',
    {
      label: 't.phone-number',
      type: UserFieldType.PHONE,
      validators: [Validators.required],
    },
  ],
  [
    'city',
    {
      label: 't.city',
      type: UserFieldType.TEXT,
      validators: [Validators.required],
    },
  ],
  [
    'address',
    {
      label: 't.address',
      type: UserFieldType.TEXT,
      validators: [Validators.required],
    },
  ],
  [
    'postal_code',
    {
      label: 't.postal-code',
      type: UserFieldType.TEXT,
      validators: [Validators.required],
      mask: 'A{12}0{12}',
    },
  ],
  [
    'country',
    {
      label: 't.country',
      type: UserFieldType.SELECT,
      options: CountryList$.pipe(map(list => list.map(country => [country.short.toUpperCase(), country.name]))),
      validators: [Validators.required],
    },
  ],
  [
    'currency',
    {
      label: 't.currency',
      type: UserFieldType.SELECT,
      options: CurrencyList$.pipe(map(list => list.map(currency => [currency.code, currency.code]))),
      validators: [Validators.required],
    },
  ],
  [
    'state',
    {
      label: 't.state',
      type: UserFieldType.SELECT,
      validators: [Validators.required],
    },
  ],
]);
