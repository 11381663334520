import { ReplaySubject } from 'rxjs';

export interface LoyaltyStatusData {
  id: string;
  name: string;
  image: string;
  points: number | string;
  bonus?: string;
  description?: string;
  prizeImg?: string;
  level?: number;
  levelsLength?: number;
  countFS?: number;
  subStatuses?: SubStatus[];
  fs?: FreeSpins;
}

export interface SubStatus {
  points: number;
  fs?: FreeSpins;
}

export interface FreeSpins {
  count: number;
  games: any;
  games$?: ReplaySubject<any>;
  rewards: number;
}

export const LoyaltyStatusesCommon: LoyaltyStatusData[] = [
  {
    id: 'beginnerwanderer',
    name: 't.status-title-beginner-wanderer',
    image: '/assets/img/loyalty/beginnerwanderer.png',
    prizeImg: '/assets/img/loyalty/rewards/1.png',
    level: 1,
    points: '0-800',
    fs: {
      games: [
        'softswiss/BookOfPyramids',
        'softswiss/BobsCoffeeShop',
        'bsg/BookofDarkness',
        'bsg/SuperSweets',
        'softswiss/DigDigDigger',
        'softswiss/WildCash',
        'softswiss/MissCherryFruits',
      ],
      count: 440,
      rewards: 48,
    },
  },
  {
    id: 'wanderer',
    name: 't.status-title-wanderer',
    image: '/assets/img/loyalty/wanderer.png',
    points: '801-2500',
    level: 2,
    fs: {
      games: [
        'softswiss/WildCash',
        'bsg/BookofDarkness',
        'softswiss/DigDigDigger',
        'softswiss/BobsCoffeeShop',
        'softswiss/AztecMagicDeluxe',
        'softswiss/AztecMagic',
        'softswiss/DeepSea',
      ],
      count: 405,
      rewards: 27,
    },
  },
  {
    id: 'pathfinder',
    name: 't.status-title-pathfinder',
    image: '/assets/img/loyalty/pathfinder.png',
    points: '2501-5000',
    level: 3,
    fs: {
      games: ['bsg/MrMacau', 'bsg/YakYetiRoll', 'bsg/ReturntoParis', 'mascot/book_of_amaterasu', 'mascot/ben_gunn_robinson'],
      count: 500,
      rewards: 21,
    },
  },
  {
    id: 'raider',
    name: 't.status-title-raider',
    image: '/assets/img/loyalty/raider.png',
    points: '5001-10000',
    level: 4,
    fs: {
      games: ['bsg/MrMacau', 'bsg/YakYetiRoll', 'bsg/ReturntoParis', 'mascot/book_of_amaterasu', 'mascot/ben_gunn_robinson'],
      count: 930,
      rewards: 31,
    },
  },
  {
    id: 'ringleader',
    name: 't.status-title-ringleader',
    image: '/assets/img/loyalty/ringleader.png',
    points: '10001-25000',
    bonus: 't.bonus-level-ringleader',
    level: 5,
    fs: {
      games: ['softswiss/PlatinumLightningDeluxe', 'softswiss/AlohaKingElvis', 'bsg/Stampede'],
      count: 1500,
      rewards: 27,
    },
  },
  {
    id: 'lootlord',
    name: 't.status-title-loot-lord',
    image: '/assets/img/loyalty/lootlord.png',
    points: '25001-40000',
    bonus: 't.bonus-level-loot-lord',
    level: 6,
    fs: {
      games: ['softswiss/PlatinumLightningDeluxe', 'softswiss/AlohaKingElvis', 'bsg/Stampede'],
      count: 1300,
      rewards: 15,
    },
  },
  {
    id: 'goldreaper',
    name: 't.status-title-gold-reaper',
    image: '/assets/img/loyalty/goldreaper.png',
    points: '40001+',
    bonus: 't.bonus-level-goldreaper',
    level: 7,
    fs: {
      games: ['softswiss/PlatinumLightningDeluxe', 'softswiss/AlohaKingElvis', 'bsg/Stampede'],
      count: 1000,
      rewards: 10,
    },
  },
];

export type LoyaltyStatusesID =
  'beginnerwanderer'
  | 'wanderer'
  | 'pathfinder'
  | 'raider'
  | 'ringleader'
  | 'lootlord'
  | 'goldreaper';

export const UserLoyaltyStatusesId: LoyaltyStatusesID[] = [
  'beginnerwanderer',
  'wanderer',
  'pathfinder',
  'raider',
  'ringleader',
  'lootlord',
  'goldreaper',
];
