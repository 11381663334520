export const PHONE_MASKS = [
  {
    country: 'AC',
    mask: ' 0000'
  },
  {
    country: 'AD',
    mask: ' 000 000'
  },
  {
    country: 'AE',
    mask: '0{15}'
  },
  {
    country: 'AF',
    mask: ' 00 000 0000'
  },
  {
    country: 'AG',
    mask: '000 0000'
  },
  {
    country: 'AI',
    mask: '000 0000'
  },
  {
    country: 'AL',
    mask: '000000 000'
  },
  {
    country: 'AM',
    mask: ' 00 000 000'
  },
  {
    country: 'AN',
    mask: '0{15}'
  },
  {
    country: 'AO',
    mask: '000000 000'
  },
  {
    country: 'AQ',
    mask: ' 00 000'
  },
  {
    country: 'AR',
    mask: '000000 0000'
  },
  {
    country: 'AS',
    mask: '000 0000'
  },
  {
    country: 'AT',
    mask: '000000 0000 || 000000 00000'
  },
  {
    country: 'AU',
    mask: ' 0 0000 0000'
  },
  {
    country: 'AW',
    mask: ' 000 0000'
  },
  {
    country: 'AZ',
    mask: ' 00 000 00 00'
  },
  {
    country: 'BA',
    mask: '0{15}'
  },
  {
    country: 'BB',
    mask: '000 0000'
  },
  {
    country: 'BD',
    mask: ' 00 000 000'
  },
  {
    country: 'BE',
    mask: '000000 000'
  },
  {
    country: 'BF',
    mask: ' 00 00 0000'
  },
  {
    country: 'BG',
    mask: '000000 000'
  },
  {
    country: 'BH',
    mask: ' 0000 0000'
  },
  {
    country: 'BI',
    mask: ' 00 00 0000'
  },
  {
    country: 'BJ',
    mask: ' 00 00 0000'
  },
  {
    country: 'BM',
    mask: '000 0000'
  },
  {
    country: 'BN',
    mask: ' 000 0000'
  },
  {
    country: 'BO',
    mask: ' 0 000 0000'
  },
  {
    country: 'BR',
    mask: '0{15}'
  },
  {
    country: 'BS',
    mask: '000 0000'
  },
  {
    country: 'BT',
    mask: '0{15}'
  },
  {
    country: 'BW',
    mask: ' 00 000 000'
  },
  {
    country: 'BY',
    mask: '00000 00 00'
  },
  {
    country: 'BZ',
    mask: ' 000 0000'
  },
  {
    country: 'CA',
    mask: '000000 0000'
  },
  {
    country: 'CD',
    mask: '000000 000'
  },
  {
    country: 'CF',
    mask: ' 00 00 0000'
  },
  {
    country: 'CG',
    mask: ' 00 000 0000'
  },
  {
    country: 'CH',
    mask: ' 00 000 0000'
  },
  {
    country: 'CI',
    mask: ' 00 000 000'
  },
  {
    country: 'CK',
    mask: ' 00 000'
  },
  {
    country: 'CL',
    mask: ' 0 0000 0000'
  },
  {
    country: 'CM',
    mask: ' 0000 0000'
  },
  {
    country: 'CN',
    mask: '0{15}'
  },
  {
    country: 'CO',
    mask: '000000 0000'
  },
  {
    country: 'CR',
    mask: ' 0000 0000'
  },
  {
    country: 'CU',
    mask: ' 0 000 0000'
  },
  {
    country: 'CV',
    mask: '00000 00'
  },
  {
    country: 'CW',
    mask: ' 000 0000'
  },
  {
    country: 'CY',
    mask: ' 00 000 000'
  },
  {
    country: 'CZ',
    mask: '000000 000'
  },
  {
    country: 'DE',
    mask: ' 00 000000000'
  },
  {
    country: 'DJ',
    mask: ' 00 00 00 00'
  },
  {
    country: 'DK',
    mask: ' 00 00 00 00'
  },
  {
    country: 'DM',
    mask: '000 0000'
  },
  {
    country: 'DO',
    mask: '0{15}'
  },
  {
    country: 'DZ',
    mask: ' 00 000 0000'
  },
  {
    country: 'EC',
    mask: '0{15}'
  },
  {
    country: 'EE',
    mask: '0{15}'
  },
  {
    country: 'EG',
    mask: '000000 0000'
  },
  {
    country: 'ER',
    mask: ' 0 000 000'
  },
  {
    country: 'ES',
    mask: '000000 000'
  },
  {
    country: 'ET',
    mask: ' 00 000 0000'
  },
  {
    country: 'FI',
    mask: '000000 00 00'
  },
  {
    country: 'FJ',
    mask: ' 00 00000'
  },
  {
    country: 'FK',
    mask: ' 00000'
  },
  {
    country: 'FM',
    mask: ' 000 0000'
  },
  {
    country: 'FO',
    mask: ' 000 000'
  },
  {
    country: 'FR',
    mask: '0{15}'
  },
  {
    country: 'GA',
    mask: ' 0 00 00 00'
  },
  {
    country: 'GD',
    mask: '000 0000'
  },
  {
    country: 'GE',
    mask: '000000 000'
  },
  {
    country: 'GF',
    mask: ' 00000 0000'
  },
  {
    country: 'GH',
    mask: '000000 000'
  },
  {
    country: 'GI',
    mask: ' 000 00000'
  },
  {
    country: 'GL',
    mask: ' 00 00 00'
  },
  {
    country: 'GM',
    mask: '00000 00'
  },
  {
    country: 'GN',
    mask: ' 00 000 000'
  },
  {
    country: 'GQ',
    mask: ' 00 000 0000'
  },
  {
    country: 'GR',
    mask: '000000 0000'
  },
  {
    country: 'GT',
    mask: ' 0 000 0000'
  },
  {
    country: 'GU',
    mask: '000 0000'
  },
  {
    country: 'GW',
    mask: ' 0 000000'
  },
  {
    country: 'GY',
    mask: ' 000 0000'
  },
  {
    country: 'HK',
    mask: ' 0000 0000'
  },
  {
    country: 'HN',
    mask: ' 0000 0000'
  },
  {
    country: 'HR',
    mask: ' 00 000 000'
  },
  {
    country: 'HT',
    mask: ' 00 00 0000'
  },
  {
    country: 'HU',
    mask: '000000 000'
  },
  {
    country: 'ID',
    mask: '0{15}'
  },
  {
    country: 'IE',
    mask: '000000 000'
  },
  {
    country: 'IL',
    mask: '0{15}'
  },
  {
    country: 'IN',
    mask: '0000000 000'
  },
  {
    country: 'IO',
    mask: ' 000 0000'
  },
  {
    country: 'IQ',
    mask: '000000 0000'
  },
  {
    country: 'IR',
    mask: '000000 0000'
  },
  {
    country: 'IS',
    mask: ' 000 0000'
  },
  {
    country: 'IT',
    mask: '0000000 000'
  },
  {
    country: 'JM',
    mask: '000 0000'
  },
  {
    country: 'JO',
    mask: ' 0 0000 0000'
  },
  {
    country: 'JP',
    mask: '0{15}'
  },
  {
    country: 'KE',
    mask: ' 000 000000'
  },
  {
    country: 'KG',
    mask: '000000 000'
  },
  {
    country: 'KH',
    mask: ' 00 000 000'
  },
  {
    country: 'KI',
    mask: ' 00 000'
  },
  {
    country: 'KM',
    mask: ' 00 00000'
  },
  {
    country: 'KN',
    mask: '000 0000'
  },
  {
    country: 'KP',
    mask: '0{15}'
  },
  {
    country: 'KR',
    mask: ' 00 000 0000'
  },
  {
    country: 'KW',
    mask: ' 0000 0000'
  },
  {
    country: 'KY',
    mask: '000 0000'
  },
  {
    country: 'KZ',
    mask: '0{15}'
  },
  {
    country: 'LA',
    mask: '0{15}'
  },
  {
    country: 'LB',
    mask: '0{15}'
  },
  {
    country: 'LC',
    mask: '000 0000'
  },
  {
    country: 'LI',
    mask: '000000 0000'
  },
  {
    country: 'LK',
    mask: ' 00 000 0000'
  },
  {
    country: 'LR',
    mask: ' 00 000 000'
  },
  {
    country: 'LS',
    mask: ' 0 000 0000'
  },
  {
    country: 'LT',
    mask: '00000 000'
  },
  {
    country: 'LU',
    mask: '000000 000'
  },
  {
    country: 'LV',
    mask: ' 00 000 000'
  },
  {
    country: 'LY',
    mask: '0{15}'
  },
  {
    country: 'MA',
    mask: ' 00 0000 000'
  },
  {
    country: 'MC',
    mask: '0{15}'
  },
  {
    country: 'MD',
    mask: ' 0000 0000'
  },
  {
    country: 'ME',
    mask: ' 00 000 000'
  },
  {
    country: 'MG',
    mask: ' 00 00 00000'
  },
  {
    country: 'MH',
    mask: ' 000 0000'
  },
  {
    country: 'MK',
    mask: ' 00 000 000'
  },
  {
    country: 'ML',
    mask: ' 00 00 0000'
  },
  {
    country: 'MM',
    mask: '0{15}'
  },
  {
    country: 'MN',
    mask: ' 00 00 0000'
  },
  {
    country: 'MO',
    mask: ' 0000 0000'
  },
  {
    country: 'MP',
    mask: '000 0000'
  },
  {
    country: 'MQ',
    mask: '00000 00 00'
  },
  {
    country: 'MR',
    mask: ' 00 00 0000'
  },
  {
    country: 'MS',
    mask: '000 0000'
  },
  {
    country: 'MT',
    mask: ' 0000 0000'
  },
  {
    country: 'MU',
    mask: ' 000 0000'
  },
  {
    country: 'MV',
    mask: ' 000 0000'
  },
  {
    country: 'MW',
    mask: '0{15}'
  },
  {
    country: 'MX',
    mask: '0{15}'
  },
  {
    country: 'MY',
    mask: '0{15}'
  },
  {
    country: 'MZ',
    mask: ' 00 000 000'
  },
  {
    country: 'NA',
    mask: ' 00 000 0000'
  },
  {
    country: 'NC',
    mask: ' 00 0000'
  },
  {
    country: 'NE',
    mask: ' 00 00 0000'
  },
  {
    country: 'NF',
    mask: ' 00 000'
  },
  {
    country: 'NG',
    mask: '0{15}'
  },
  {
    country: 'NI',
    mask: ' 0000 0000'
  },
  {
    country: 'NL',
    mask: ' 00 000 0000'
  },
  {
    country: 'NO',
    mask: '00000 000'
  },
  {
    country: 'NP',
    mask: ' 00 000 000'
  },
  {
    country: 'NR',
    mask: ' 000 0000'
  },
  {
    country: 'NU',
    mask: ' 0000'
  },
  {
    country: 'NZ',
    mask: '0{15}'
  },
  {
    country: 'OM',
    mask: ' 00 000 000'
  },
  {
    country: 'PA',
    mask: ' 000 0000'
  },
  {
    country: 'PE',
    mask: '000000 000'
  },
  {
    country: 'PF',
    mask: ' 00 00 00'
  },
  {
    country: 'PG',
    mask: '00000 000'
  },
  {
    country: 'PH',
    mask: '000000 0000'
  },
  {
    country: 'PK',
    mask: '000000 0000'
  },
  {
    country: 'PL',
    mask: '000000 000'
  },
  {
    country: 'PS',
    mask: ' 00 000 0000'
  },
  {
    country: 'PT',
    mask: ' 00 000 0000'
  },
  {
    country: 'PW',
    mask: ' 000 0000'
  },
  {
    country: 'PY',
    mask: '000000 000'
  },
  {
    country: 'QA',
    mask: ' 0000 0000'
  },
  {
    country: 'RE',
    mask: ' 00000 0000'
  },
  {
    country: 'RO',
    mask: ' 00 000 0000'
  },
  {
    country: 'RS',
    mask: ' 00 000 0000'
  },
  {
    country: 'RU',
    mask: '000000 00 00'
  },
  {
    country: 'RW',
    mask: '000000 000'
  },
  {
    country: 'SA',
    mask: '0{15}'
  },
  {
    country: 'SB',
    mask: '0{15}'
  },
  {
    country: 'SC',
    mask: ' 0 000 000'
  },
  {
    country: 'SD',
    mask: ' 00 000 0000'
  },
  {
    country: 'SE',
    mask: ' 00 000 0000'
  },
  {
    country: 'SG',
    mask: ' 0000 0000'
  },
  {
    country: 'SH',
    mask: ' 0000'
  },
  {
    country: 'SI',
    mask: ' 00 000 000'
  },
  {
    country: 'SK',
    mask: '000000 000'
  },
  {
    country: 'SL',
    mask: ' 00 000000'
  },
  {
    country: 'SM',
    mask: ' 0000 000000'
  },
  {
    country: 'SN',
    mask: ' 00 000 0000'
  },
  {
    country: 'SO',
    mask: '0{15}'
  },
  {
    country: 'SR',
    mask: ' 000 0000'
  },
  {
    country: 'SS',
    mask: ' 00 000 0000'
  },
  {
    country: 'ST',
    mask: ' 00 00000'
  },
  {
    country: 'SV',
    mask: ' 00 00 0000'
  },
  {
    country: 'SX',
    mask: '000 0000'
  },
  {
    country: 'SY',
    mask: ' 00 0000 000'
  },
  {
    country: 'SZ',
    mask: ' 00 00 0000'
  },
  {
    country: 'TC',
    mask: '000 0000'
  },
  {
    country: 'TD',
    mask: ' 00 00 00 00'
  },
  {
    country: 'TG',
    mask: ' 00 000 000'
  },
  {
    country: 'TH',
    mask: '0{15}'
  },
  {
    country: 'TJ',
    mask: ' 00 000 0000'
  },
  {
    country: 'TK',
    mask: ' 0000'
  },
  {
    country: 'TL',
    mask: '0{15}'
  },
  {
    country: 'TM',
    mask: ' 0 000 0000'
  },
  {
    country: 'TN',
    mask: ' 00 000 000'
  },
  {
    country: 'TO',
    mask: ' 00000'
  },
  {
    country: 'TR',
    mask: '000000 0000'
  },
  {
    country: 'TT',
    mask: '000 0000'
  },
  {
    country: 'TV',
    mask: '000000000000000'
  },
  {
    country: 'TW',
    mask: '000000000000000'
  },
  {
    country: 'TZ',
    mask: ' 00 000 0000'
  },
  {
    country: 'UA',
    mask: '00000 00 00'
  },
  {
    country: 'UG',
    mask: '000000 000'
  },
  {
    country: 'UK',
    mask: ' 00 0000 0000'
  },
  {
    country: 'US',
    mask: '000000 0000'
  },
  {
    country: 'UY',
    mask: ' 0 000 00 00'
  },
  {
    country: 'UZ',
    mask: ' 00 000 0000'
  },
  {
    country: 'VA',
    mask: '   00000'
  },
  {
    country: 'VC',
    mask: '000 0000'
  },
  {
    country: 'VE',
    mask: '000000 0000'
  },
  {
    country: 'VG',
    mask: '000 0000'
  },
  {
    country: 'VI',
    mask: '000 0000'
  },
  {
    country: 'VN',
    mask: '0{15}'
  },
  {
    country: 'VU',
    mask: '0{15}'
  },
  {
    country: 'WF',
    mask: ' 00 0000'
  },
  {
    country: 'WS',
    mask: ' 00 0000'
  },
  {
    country: 'YE',
    mask: '0{15}'
  },
  {
    country: 'ZA',
    mask: ' 00 000 0000'
  },
  {
    country: 'ZM',
    mask: ' 00 000 0000'
  },
  {
    country: 'ZW',
    mask: ' 0 000000'
  }
];
