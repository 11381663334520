import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { GamesLauncherService } from './games-launcher.service';
import { UserService } from '../user/user.service';
import { GamesService } from './games.service';
import { CmsApiService } from '../api/cms-api.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteGamesService {

  /**
   * List of favorite games
   */
  private _gameList: Array<any> = [];

  /**
   * Emits when favorite games list loaded
   */
  private _listReady$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Access to GamesService
   */
  private _games: GamesService;

  /**
   * Emits when favorite status toggled
   */
  public toggleFavorite$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Emit providers list for favorite games
   */
  public favoriteProviders$: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

  constructor(
    private _apiSs: SsApiService,
    private _api: CmsApiService,
    private _launcher: GamesLauncherService,
    private _user: UserService,
  ) {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      switchMap(() => this.getFavoriteGames()),
    ).subscribe();
  }

  /**
   * For avoiding circular dependency
   */
  set gamesService(service: GamesService) {
    this._games = service;
  }

  /**
   * Returns ready to use favorite games list
   */
  public getMappedGameList(): Observable<any> {
    return this._listReady$.pipe(
      switchMap(() => this._games.list({
        'game_id[]': this._gameList.length ? this._gameList : null,
        offset: 0,
        limit: 25,
      })),
      map(list => {
        if (list && list.gameList) {
          list.gameList = list.gameList.filter(game => this._gameList.includes((this._launcher.resolveGameId(game) || { id: null }).id));
        }

        return list;
      }),
      tap(list => this.favoriteProviders$.next(list && list.gameList && list.gameList.map(game => game.providerId))),
    );
  }

  /**
   * Define favorite attribute in game object
   *
   * @param gameObject
   */
  public setFavoriteStatus(gameObject: any) {
    const game = gameObject;
    const id = (this._launcher.resolveGameId(game) || { id: null }).id;

    this._listReady$.pipe(
      first(),
      tap(() => {
        game.favorite = this._gameList.includes(id);
      }),
    ).subscribe();
  }

  /**
   *  Returns favorite game list from backend and save result to local var
   */
  public getFavoriteGames(): Observable<any> {
    return this._apiSs.playerFavoriteGames().pipe(
      tap(list => {
        this._gameList = list;
        this._listReady$.next(true);
      }),
      catchError(error => of([])),
    );
  }

  /**
   * Toggle game favorite status
   *
   * @param gameObject
   */
  public toggleFavorite(gameObject: any) {
    if (this._user.auth) {
      const game = gameObject;

      if (game.favorite) {
        this.removeFromFavorite(game).subscribe(this._emitToggleFavorite.bind(this));
      } else {
        this.addToFavorite(game).subscribe(this._emitToggleFavorite.bind(this));
      }
    } else {
      this._user.authUser().then();
    }
  }

  /**
   * Add game to favorite
   *
   * @param gameObject
   */
  public addToFavorite(gameObject: any): Observable<any> {
    const game = gameObject;
    const id = this._launcher.resolveGameId(game).id;
    game.favorite = true;

    this._api.postAccountFavorite({ 'user_id': this._user.info.id }, {
      id: game.id,
      user_country: this._user.info.country,
    }).subscribe();

    return this._apiSs.putPlayerFavoriteGames(id, { id }).pipe(
      switchMap(() => this.getFavoriteGames()),
      tap(() => this.setFavoriteStatus(game)),
    );
  }

  /**
   * Remove game from favorite
   *
   * @param gameObject
   */
  public removeFromFavorite(gameObject: any): Observable<any> {
    const game = gameObject;
    const id = this._launcher.resolveGameId(game).id;
    game.favorite = false;

    this._api.deleteAccountFavorite({ 'user_id': this._user.info.id }, {
      id: game.id,
      user_country: this._user.info.country,
    }).subscribe();

    return this._apiSs.deletePlayerFavoriteGames(id, { id }).pipe(
      switchMap(() => this.getFavoriteGames()),
      tap(() => this.setFavoriteStatus(game)),
    );
  }

  /**
   * Emits toggleFavorite RS
   * @private
   */
  private _emitToggleFavorite() {
    this.toggleFavorite$.next(true);
  }
}
