import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {UserInfoService} from './user-info.service';
import {SsPaymentsV2Service} from '../../vendor/ss-payments-v2/ss-payment.service';

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}

interface IMask {
  country: string;
  mask: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserPhoneService {

  private _hasVerifiedPhone: boolean;

  public prefix: string;

  public mask: IMask;

  constructor(
    private _api: SsApiService,
    private _userInfo: UserInfoService,
    private _ssPayments: SsPaymentsV2Service
  ) { }


  get verified(): boolean {
    return this._hasVerifiedPhone;
  }

  /**
   * Returns list of player phone numbers
   */
  getPhoneList(): Observable<any> {
    return this._api.playerPhone().pipe(
      tap(list => this._hasVerifiedPhone = list.some(phone => phone.verified_status === PhoneStatus.VERIFIED)),
      map(list => list?.map(p => ({...p, 'phone_number': this.cutFrCallingCode(p?.phone_number)})))
    );
  }

  /**
   * Add user phone
   *
   * @param data
   */
  addPhone(data: object): Observable<any> {
    return this._api.postPlayerPhone({
      phone: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Delete user phone
   */
  deletePhone(): Observable<any> {
    return this._api.deletePlayerPhone().pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Verify player [hone
   *
   * @param code
   */
  verifyPhone(code: string): Observable<any> {
    return this._api.playerPhoneConfirm({
      phone: {
        code
      }
    });
  }

  /**
   * Request verification code
   */
  resendVerificationCode(): Observable<any> {
    return this._api.playerPhoneResendVerificationCode();
  }

  /**
   * Cut fr calling code
   * @param phone
   */
  public cutFrCallingCode(phone: string) {
    return this._userInfo.isFR && phone.slice(0, 3) === '+33' ? phone.slice(3) : this._userInfo.isFR && phone.slice(0, 2) === '33' ? phone.slice(2) : phone;
  }
}
