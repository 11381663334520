export const ZENDESK_SCRIPT_URL = `https://static.zdassets.com/ekr/snippet.js`;
export const DEFAULT_DEPARTMENTS_LIST = [
  'Bonus issues WFio',
  'Deposit Assistance WFio',
  'High Roller promotions WFio',
  'Other issues WFio',
  'Reopen account WFio',
  'Technical issue WFio',
  'Verification process WFio',
  'Withdrawal WFio',
  'Extra bonus WFio',
];
export const VIP_DEPARTMENT = 'VIP_General_WF.IO';
export const OFFLINE_DEPARTMENT = 'Offline_WF.IO';
export const FIVE_DOLLARS_DEPARTMENTS = '5$_WF.IO';

export const VIP_CHAT_EXPIRE_AT_COOKIE = 'vipChatExpireAt';

export const zendeskStag = '84397';

export interface IZendeskUserData {
  name?: string;
  email?: string;
}
