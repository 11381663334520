import { Injectable } from '@angular/core';
import { GamesService } from './games.service';
import { SsApiService } from '../api/ss-api.service';
import { FiltersService } from 'ngx-unificator/services';
import {Observable, ReplaySubject} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LastPlayedGamesService {

  /**
   * Access to GamesService
   */
  private _games: GamesService;

  /**
   * Emit providers list for last-played games
   */
  public lastPlayedProviders$: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

  constructor(
    private _api: SsApiService,
    private _filters: FiltersService
  ) { }

  /**
   * For avoiding circular dependency
   */
  set gamesService(service: GamesService) {
    this._games = service;
  }

  /**
   * Returns ready to use favorite games list
   */
  getMappedGameList(): Observable<any> {
    let idList = [];

    return this._api.playerPlayedGames().pipe(
      map(response => (response || []).map(game => game.identifier)),
      switchMap(identifiers => {
        idList = identifiers;
        return this._games.list({
          'external_id[]': identifiers.length ? identifiers : null,
        });
      }),
      map(response => ({
        ...response,
        gameList: this._filters.sortByArrayOrder(idList, 'externalId', response.gameList)
      })),
      tap(res => this.lastPlayedProviders$.next(res && res.gameList && res.gameList.map(game => game.providerId)))
    );
  }
}
