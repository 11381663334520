import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalContent, ModalRef } from '../../../modal-ref';
import { FieldDescriptor } from '../../../../services/user/user-info.service';
import { UserFieldDescriptor } from '../../../../helpers/user/user-fields.data';
import { FormGroup } from '@angular/forms';
import { UserProfileService } from '../../../../services/user/user-profile.service';
import { Observable } from 'rxjs';
import { ZendeskChatService } from '../../../../services/zendesk/zendesk-chat.service';
import {
  AuthFieldsMissedComponent,
} from '../../../../shared/components/auth-fields-missed/auth-fields-missed.component';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-auth-fields-missed-update-modal',
  templateUrl: './app-auth-fields-missed-update-modal.component.html',
  styleUrls: ['./app-auth-fields-missed-update-modal.component.scss'],
  imports: [
    AuthFieldsMissedComponent,
  ],
})
export class AuthFieldsMissedUpdateModalComponent implements ModalContent {

  public modal: ModalRef;

  constructor(
    public userProfile: UserProfileService,
    private _zendesk: ZendeskChatService,
  ) {
  }

  get fieldsList$(): Observable<(UserFieldDescriptor & FieldDescriptor)[]> {
    return this.modal.options.data;
  }

  public onSubmit(form: FormGroup) {
    this.userProfile.submit(form, 'edition');
    this.userProfile.userProfileUpdated$.pipe(
    ).subscribe(e => {
      setTimeout(() => {
        this._zendesk.setUserData();
      }, 5000);
      if (e) this.modal.close({ updated: true });
    });
  }

}
